.home_title {
  font-size: 40px;
  font-weight: 300;
  color: #2C7370;
}

.home_tagline {
  font-size: 20px;
  font-weight: 200;
  color: #2C7370;
}

.callButton {
  font-weight: 300;
  background-color: #2C7370;
  border-radius: 5px;
  width: 14rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.callButtonLink {
  font-size: 20px;
  text-decoration: none;
  color: white;
}

.layer0 {
  z-index: 0;
}

.layer1 {
  z-index: 1;
}

.layer2 {
  z-index: 2;
}

.main_img {
  width: 46%;
  height: 17rem;
}

.top_text {
  width: 30rem;
  height: 17rem;
}

.buttonHolder {
  display: flex;
  justify-content: space-between;
}

.second_img {
  width: 30rem;
}

.bottom_holder {
  width: 36rem;
}

.bottom_bar {
  padding: 3rem;
}

.top_bar {
  padding: 4rem 8%;
}

.touch_floor {
  bottom: 0;
}

@media (max-width: 1220px) {
  .main_img {
    min-width: 80%;
    height: 16rem;
  }

  .top_bar {
    flex-direction: column-reverse;
    align-items: center;
    padding: 4rem 0rem;
  }

  .bottom_bar {
    margin-top: 0rem;
    margin-bottom: 4rem;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .top_text {
    width: 80%;
    margin-top: 2rem;
    height: auto;
  }

  .callButton {
    margin-top: 2rem;
    width: 10rem;
    height: 5rem;
    text-align: center;
  }

  .buttonHolder {
    justify-content: space-around;
    margin-top: 2rem;
  }

  .second_img {
    width: 80%;
    margin-bottom: 2rem;
  }

  .bottom_holder {
    width: 80%;
    margin-top: 2rem;
  }

  .touch_floor {
    bottom: -4rem;
  }
}