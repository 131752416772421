* {
  margin: 0;
  padding: 0;
  font-family: quasimoda, sans-serif;
}

h1 {
  font-size: 20px;
  font-weight: 400;
}

h2 {
  font-size: 16px;
  font-weight: 600;
}

ul {
  list-style-type:circle;
}

li {
  margin-bottom: .2rem;
  font-weight: 200;
}

.page_header_img {
  width: 100%;
  height: 22rem;
  object-fit: cover;
}

.page_header {
  color: white;
  text-align: center;
  position: absolute;
  top: 30%;
  width: 100%;
  font-size: 48px;
  font-weight: 400;
}

.navLink {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

.bottomPad {
  margin-bottom: 1rem;
}

.bottomPadSmall {
  margin-bottom: 0.5rem;
}

.hours_list {
  display: flex;
}

.spaceLeft {
  margin-left: 2rem;
}

.footer_section {
  margin: 1rem;
}